kpi-chart {
    min-height: 100% !important;
    
    nvd3 {
        svg {
            background-color: #fafafa;
            border-left: 1px solid #dfdfdf;
            border-bottom: 1px solid #dfdfdf;
            border-right: 1px solid #e7e7e7;
            border-top: 1px solid #f4f4f4;
        }
    }
}

.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
    fill-opacity: 1;
    stroke-opacity: 0;
}