/*----------------------------------------------------------------*/
/*  search-bar
/*----------------------------------------------------------------*/
.search-bar {
    height: $toolbarHeight;
    font-size: 13px;

    #search-bar-expander,
    #search-bar-collapser {
        cursor: pointer;
        padding: 0 20px;
        width: 64px !important;
        height: $toolbarHeight !important;
        line-height: $toolbarHeight !important;
    }

    #search-bar-expander {

    }

    #search-bar-collapser {
        display: none;
    }

    #search-bar-input {
        display: none;
        width: 100%;
        //min-height: $toolbarHeight;
        background-color: transparent;
    }

    &.expanded {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #FFFFFF;
        z-index: 10;

        #search-bar-input {
            display: block;
        }

        #search-bar-expander {
            display: none;
        }

        #search-bar-collapser {
            display: block;
        }
    }
}

// RESPONSIVE

@media screen and (max-width: $layout-breakpoint-sm) {

    .search-bar-label {
        padding: 0 8px 0 12px;
    }
}