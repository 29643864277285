#tasks {

    // Single scroll
    &.single-scroll {

        .tasks-list-pane {
            overflow: hidden;
        }
    }

    .tasks-list-pane {

        .tasks-items {

            &.sortable {

                .tasks-item {
                    padding: 16px 16px 16px 48px;

                    .handle {
                        display: block;
                    }
                }
            }
        }

        .tasks-items {
            overflow-x: hidden;

        }
    }
}

.tasks-item {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer;

    &.tasks-item-placeholder {
        background: rgba(0, 0, 0, 0.12);
        * {
            opacity: 0;
        }
    }

    .handle {
        display: none;
        position: absolute;
        height: 100%;
        width: 48px;
        top: 0;
        left: 0;
        cursor: move;
        text-align: center;

        md-icon {
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .tags {
        font-size: 12px;

        .tag {
            border-radius: 2px;
            margin: 8px 4px 0 0;
            padding: 3px 8px;
            background-color: rgba(0, 0, 0, 0.08);

            .tag-color {
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 50%;
            }
        }
    }

    &.completed {
        background: #EEEEEE;

        .title,
        .notes {
            color: rgba(0, 0, 0, 0.54);
            text-decoration: line-through;
        }
    }

    &.selected {
        background: #FFF8E1;
    }

    .info {
        margin: 0 16px 0 8px;

        .title {
            font-size: 15px;
            font-weight: 500;
        }

        .notes {
            margin-top: 4px;
        }
    }

    .buttons {

        .is-starred {
            margin: 0 0 0 16px;
        }

        .is-important {
            margin: 0;
        }
    }
}

.tasks-item-ghost {
    opacity: 1 !important;
    background: #FFFFFF;
    box-shadow: $whiteframe-shadow-14dp;
    border: none;

    .md-ripple-container {
        display: none;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #tasks {

        .tasks-list-pane {

            .sortable {

                .tasks-items {

                    .tasks-item {
                        padding-left: 32px;
                    }
                }
            }

            .tasks-items {

                .tasks-item {

                    .handle {
                        width: 32px;
                    }
                }
            }
        }
    }
}