/*----------------------------------------------------------------*/
/*  context-bar
/*----------------------------------------------------------------*/
.context-bar {
    font-size: 13px;
    
    .icon-ctx {
        cursor: pointer;
    }
    
    .amber-600-fg {
        color: teal !important;
    }
}