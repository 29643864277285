.admin-tools {
    #db-association-view {
        .target-item {
            margin-bottom: 4px;
            margin-right: 10px;
            -webkit-box-shadow: inset 0 0 2px #434343;
            -ms-box-shadow: inset 0 0 2px #434343;
            box-shadow: inset 0 0 2px #434343;
            text-align: center;
        }
        
        .source-item {
            margin-bottom: 4px;
            margin-right: 10px;
            -webkit-box-shadow: inset 0 0 2px #434343;
            -ms-box-shadow: inset 0 0 2px #434343;
            box-shadow: inset 0 0 2px #434343;
            text-align: center;
        }

        .list-panel-outside {
            padding: 10px;
            border: 1px dotted #9a9a9a;
            background: #ffffff;
        }

        .list-panel-outside-accent {
            padding: 10px;
            border: 1px dotted #9a9a9a;
            background: #454545;
        }

        .list-panel {
            height: 300px;
        }

        .item-selected {
            background-color: #eaeaea;
        }

        .search {
            height: 56px;
            line-height: 56px;
            padding: 10px;
            background: #ffffff;
            margin-bottom: 10px;

            .icon {
                margin: 0;
                margin-right: 5px;
            }

            input {
                padding-left: 16px;
                height: 56px;
                color: rgba(0, 0, 0, 0.54);
                fnot-size: 1.4em;
            }
        }
    }
    
    .left-align {
        text-align: left;
    }
}