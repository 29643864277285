.input-standard {
    md-input-container {

        textarea {
            //height: 150px !important;
            font-size: 1.1em;
        }

        input {
            font-size: 1.1em;
        }

        p {

        }

        label {
            font-size: 1.1em;
        }
    }

    .info-gt {
        font-size: 1.9em;
    }

    span {
        font-size: 1.1em;
    }
}