/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */

// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */

// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/home/apps/admin/ation-plan-without-department/ac-without-dept.scss";
@import "main/home/apps/admin/db-association/db-association.scss";
@import "main/home/apps/kpi/results/indicator-results.scss";
@import "main/home/apps/activiti/sidenavs/main/main-sidenav.scss";
@import "main/home/apps/activiti/dialogs/process/process-dialog.scss";
@import "main/home/apps/activiti/dialogs/task/task-dialog.scss";
@import "main/home/apps/activiti/views/list/list-view.scss";
@import "main/home/apps/kpi/dialogs/indicators/indicator-dialog.scss";
@import "main/home/apps/kpi/dialogs/manual-results/manual-results-dialog.scss";
@import "main/home/apps/kpi/directives/chart/kpi-chart.scss";
@import "main/home/apps/kpi/directives/action-plan/action-plan.scss";
@import "main/home/apps/kpi/directives/result-analysis/result-analysis.scss";
@import "main/home/apps/kpi/results/attachment/attach-result.scss";
@import "toolbar/toolbar.scss";
@import "quick-panel/quick-panel.scss";
@import "main/login/login.scss";
@import "main/home/home.scss";
@import "toolbar/context-bar/context-bar.scss";
@import "toolbar/search-bar/search-bar.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "quick-panel/tabs/recent-analytics/recent-analytics-tab.scss";
@import "main/container/views/about/about.scss";
@import "main/home/apps/activiti/tasks.scss";
@import "main/home/apps/admin/admin-tools.scss";
@import "main/home/apps/kpi/kpi.scss";
// endinjector
.alert {
	background-color: #ff7766;
	color: white;
	md-icon {
		color: white;
	}
}

.paginate_button {
	cursor: pointer !important
}

.dataTables_paginate {
	text-align: right;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	background-repeat: no-repeat !important;
	background-position: center right !important;
}

table.dataTable thead .sorting {
	background-image: url('http://cdn.datatables.net/1.10.12/images/sort_both.png') !important;
}

table.dataTable thead .sorting_asc {
	background-image: url('http://cdn.datatables.net/1.10.12/images/sort_asc.png') !important;
}

table.dataTable thead .sorting_desc {
	background-image: url('http://cdn.datatables.net/1.10.12/images/sort_desc.png') !important;
}

@media screen and (min-width: 767px) {
	.dataTables_paginate {
		margin-top: -25px !important;
	}
	.dataTables_filter {
		margin-top: -35px !important;
	}
}

.md-button.md-fab.md-fab-bottom-right {
	position: fixed !important;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
	display: none !important;
}

//html {
//  zoom: 90%;
//  -moz-transform: scale(0.9);
//}
