.ms-stepper {

    .ms-stepper-horizontal {
        background: #FFFFFF;
        box-shadow: $whiteframe-shadow-1dp;

        .ms-stepper-navigation-wrapper {

            .ms-stepper-navigation {
                height: 72px;
                min-height: 72px;
                max-height: 72px;
                background: #FFFFFF;
                box-shadow: $whiteframe-shadow-1dp;

                .ms-stepper-navigation-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    height: 72px;
                    min-height: 72px;
                    max-height: 72px;
                    line-height: 24px;
                    margin: 0;
                    padding: 24px;
                    border-radius: 0;
                    cursor: pointer;
                    font-size: 14px;
                    text-transform: none;

                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        width: 24px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    &:before {
                        left: 0;
                    }

                    &:after {
                        right: 0;
                    }

                    &:first-child {

                        &:before {
                            display: none;
                        }
                    }

                    &:last-child {

                        &:after {
                            display: none;
                        }
                    }

                    &.current {

                        .step {

                        }

                        .title {
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.87);
                        }
                    }

                    &.disabled {
                        cursor: default;

                        .step {
                            background: rgba(0, 0, 0, 0.12);
                        }

                        .title {
                            color: rgba(0, 0, 0, 0.27);
                        }

                        .subtitle {
                            color: rgba(0, 0, 0, 0.27);
                        }
                    }

                    &.optional {
                        padding: 16px 24px;
                    }

                    .step {
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin: 0 8px 0 16px;
                        color: #FFFFFF;

                        .icon {
                            color: #FFFFFF;
                            font-weight: bold;
                            line-height: 24px !important;
                        }
                    }

                    .title {
                        padding-right: 16px;
                        color: rgba(0, 0, 0, 0.54);
                    }

                    .subtitle {
                        font-size: 12px;
                        padding-right: 16px;
                        color: rgba(0, 0, 0, 0.54);
                        line-height: 1;
                    }
                }
            }
        }

        .ms-stepper-steps {
            padding: 24px 16px;
        }

        .ms-stepper-controls {
            padding: 0 16px 16px 16px;

            .ms-stepper-dots {
                display: none;
                align-items: center;
                justify-content: center;
                padding: 0 16px;

                span {
                    width: 5px;
                    height: 5px;
                    margin: 0 4px;
                    border-radius: 50%;

                    &:not(.md-accent-bg) {
                        background: rgba(0, 0, 0, 0.12);
                    }

                    &.selected {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    .ms-stepper {

        .ms-stepper-horizontal {

            .ms-stepper-navigation-wrapper {

                .ms-stepper-navigation {
                    display: none;
                }
            }

            .ms-stepper-controls {

                .ms-stepper-dots {
                    display: flex;
                }
            }
        }
    }
}