#quick-panel {

    .activity-tab {

        .recents {

            .recent {
                position: relative;

                .status {
                    position: absolute;
                    top: 8px;
                    left: 44px;
                    width: 18px;
                    height: 18px;
                    z-index: 10;
                    border-radius: 50%;
                    border: 3px solid #FFFFFF;

                    &.online {
                        background-color: #00C853;
                    }

                    &.offline {
                        background-color: #D50000;
                    }

                    &.away {
                        background-color: #FF6D00;
                    }
                }

                .unread-message-count {
                    position: absolute;
                    left: 16px;
                    bottom: 16px;
                    width: 18px;
                    height: 18px;
                    z-index: 10;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                }
            }
        }
    }
}