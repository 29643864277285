.admin-tools {
    #acwithoutdept-view {
        .target-item {
            margin-bottom: 4px;
            margin-right: 10px;
            -webkit-box-shadow: inset 0 0 2px #434343;
            -ms-box-shadow: inset 0 0 2px #434343;
            box-shadow: inset 0 0 2px #434343;
            text-align: center;
        }
        
        .source-item {
            margin-bottom: 4px;
            margin-right: 10px;
            -webkit-box-shadow: inset 0 0 2px #434343;
            -ms-box-shadow: inset 0 0 2px #434343;
            box-shadow: inset 0 0 2px #434343;
            text-align: center;
        }

        .list-panel-outside {
            padding: 10px;
            border: 1px dotted #9a9a9a;
            background: #ffffff;
        }

        .list-panel-outside-accent {
            padding: 10px;
            border: 1px dotted #9a9a9a;
            background: #454545;
        }

        .list-panel {
            height: 400px;
        }

        .item-selected {
            background-color: #eaeaea;
        }
    }
}