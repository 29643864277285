.result-table {
    .icon-teal {
        opacity: 1;
        color: teal;
    }
    
    .icon-transparent {
        opacity: 0.3;
        color: black;
    }
}